import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '42, 45, 136',
		'primary-dark': '31, 33, 97',
		'accent': '236, 0, 140',
		'accent-plus': '255, 255, 255',
	},
});
