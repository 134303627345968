export default {
	cinema: {
		address: 'ул. Космонавтов, 7',
		place: null,
		phoneNumbers: ['+7 (906) 283-10-18', '+7 (81852) 4-32-60'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/club122338502',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: false,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CCg9JTnk',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
		isWidgetDisabled: false,
	},
	feedback: true,
};
